/* eslint-disable no-unused-vars */
import React, { useEffect, lazy, Suspense } from 'react';
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
const Home = lazy(() => import("../views/Home"));
const HomeV2 = lazy(() => import("../views/HomeV2"));
const Lives = lazy(() => import("../views/Lives"));
const Live = lazy(() => import("../views/Live"));
const MyCourses = lazy(() => import("../views/MyCourses"));
const RegistrationData = lazy(() => import("../views/RegistrationData"))
const RegisteredCards = lazy(() => import("../views/RegisteredCards"))
const Course = lazy(() => import("../views/Course"));
const CourseModule = lazy(() => import("../views/CourseModule"));
const LogIn = lazy(() => import('../views/Login'));
const Register = lazy(() => import('../views/Register'));
const PasswordRecovery = lazy(() => import('../views/PasswordRecovery'));
const passwordRecoveryInvestMe = lazy(() => import('../views/PasswordRecoveryInvestMe'));
const NotFound = lazy(() => import('../views/NotFound'));
const CourseLP = lazy(() => import('../views/CourseLP'));
const UserHome = lazy(() => import('../views/UserHome'));
const Unavailable = lazy(() => import('../views/Unavailable'));
const MyPurchases = lazy(() => import('../views/MyPurchases'));
const Checkout = lazy(() => import('../views/Checkout'));
const MasterCourse = lazy(() => import('../views/MasterCourse'));
const ValidRegister = lazy(() => import('../views/ValidRegister'));
const MyCertificates = lazy(() => import('../views/MyCertificates'));
const AboutUs = lazy(() => import('../views/AboutUs'));
const AllCourses = lazy(() => import('../views/AllCourses'));
const LPEducational = lazy(() => import('../views/LPEducational'));
const Boleto = lazy(() => import('../views/Boleto'));
const Mentoria2MV = lazy(() => import('../views/Mentoria2MV'));
const MentoriaInternacional = lazy(() => import('../views/MentoriaInternacional'));
const FormacaoAutoTrading = lazy(() => import('../views/FormacaoAutoTrading'));
const LandingPage = lazy(() => import('../views/LandingPage'));
const VDPPage = lazy(() => import('../views/VDPPage'));

import { MainContainer } from './styles';
import { supportedLngs } from '../plugins/i18n';
import { product } from '../styles/theme/themes';
import { useSelector } from 'react-redux';
import EnvHelper from '../helpers/EnvHelper';
import UserTrackingHelper from '../helpers/UserTrackingHelper';
import LanguageHelper from '@common/classes/helpers/LanguageHelper';

const Content = ({ match }) => {
  const history = useHistory();
  const { i18n: {language, changeLanguage} } = useTranslation();
  const { isLoggedIn } = useSelector(state => state.auth);
  const { user } = useSelector((state) => state.user);
  const { pathname } = useLocation();

  useEffect(() => {
    const isAcceptedLanguages = supportedLngs.includes(match.params.locale);
    if (!isAcceptedLanguages) {
      changeLanguage(language);
      history.replace(`/${language}/`);
    }
  }, [changeLanguage, language, history, match]);

  useEffect(() => {
    const bMustSkip = UserTrackingHelper.mustSkipTrackRoute(pathname.replace(match.url, ''));

    if (bMustSkip) return;

    const insiderType = UserTrackingHelper.getInsiderType(pathname.replace(match.url, ''));

    const userInfos = {
      gdpr_optin: true,
      language: LanguageHelper.getLanguageByCode(language)?.code?.replace('-', '_') || '',
    };

    if (isLoggedIn) userInfos.uuid = user.id.toString();

    UserTrackingHelper.trackNavigation(insiderType, userInfos, null);
  }, [pathname]);

  const verifyMarginByTheme = () => {
    return isLoggedIn && (pathname.endsWith("/lives") ||
      pathname.endsWith("/home") ||
      pathname.endsWith("/courses") || 
      pathname.endsWith("/registration-data") ||
      pathname.endsWith("/registration-cards"));
  }

  const homeComponent = () => {
    return Home;
    // const themesWithHomeV2 = [
    //   "INVESTACADEMY",
    // ];
    // return themesWithHomeV2.includes(product) ? HomeV2 : Home;
  }

  const isInvestAcademy = () => {
    return product === "INVESTACADEMY";
  }

  return (
    <MainContainer product={product} isLoggedIn={verifyMarginByTheme()}>
      <Suspense fallback={<></>}>
        <Switch>
          <Route path={`${match.url}/login`} component={!EnvHelper.isInvestMe() ? LogIn : UserHome} />
          <Route path={`${match.url}/register`} component={Register} />
          <Route path={`${match.url}/password-recovery`} component={PasswordRecovery} />
          <Route path={`${match.url}/recuperacao-senha`} component={EnvHelper.isInvestMe() ? passwordRecoveryInvestMe : PasswordRecovery} />
          <Route path={`${match.url}/routing-password-recovery/:id?`} component={passwordRecoveryInvestMe} />
          <Route path={`${match.url}/courselp/:id`} component={CourseLP} />
          <Route path={`${match.url}/home`} component={UserHome} />
          <Route exact path={`${match.url}/registration-data`} component={RegistrationData} />
          <Route exact path={`${match.url}/registration-cards`} component={RegisteredCards} />
          <Route exact path={`${match.url}/courses`} component={MyCourses} />
          <Route exact path={`${match.url}/courses/:id?`} component={Course} />
          <Route path={`${match.url}/courses/:classId?/modules/:moduleId?/classes/:id?`} component={CourseModule} />
          <Route path={`${match.url}/lives`} component={Lives} />
          <Route path={`${match.url}/class/:classId?/lives/:liveId?`} component={Live} />
          <Route path={`${match.url}/unavailable`} component={Unavailable} />
          <Route path={`${match.url}/purchases`} component={MyPurchases} />
          <Route path={`${match.url}/boleto`} component={Boleto} />
          <Route path={`${match.url}/checkout/sign-in/:nClassId?`} component={Checkout} />
          <Route path={`${match.url}/checkout/sign-up/:nClassId?`} component={Checkout} />
          <Route path={`${match.url}/certificates`} component={MyCertificates} />
          <Route path={`${match.url}/about-us`} component={AboutUs} />
          <Route path={`${match.url}/all-courses`} component={AllCourses} />
          <Route path={`${match.url}/mba`} component={MasterCourse} />
          <Route path={`${match.url}/wl-educational`} component={LPEducational} />
          <Route path={`${match.url}/valid-register/:id?`} component={ValidRegister} />
          <Route exact path={`${match.url}/`} component={homeComponent()} />
          <Route exact path={`${match.url}/mentoria2mv`} component={Mentoria2MV} />
          <Route path={`${match.url}/mentoria-internacional`} component={MentoriaInternacional} />
          <Route exact path={`${match.url}/formacaoAutoTrading`} component={FormacaoAutoTrading} />
          <Route path={`${match.url}/landing-page/:slug`} component={LandingPage} />
          { isInvestAcademy() && <Route path={`${match.url}/vulnerability-disclosure-program`} component={VDPPage} /> }
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </MainContainer>
  );
};

export default Content;
